import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

// import PerfectScrollbar from "perfect-scrollbar";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import Footer from "components/Footers/Footer.jsx";

class BMP extends React.Component {
  componentDidMount() {
    // if (!this.ps) {
    //   this.ps = new PerfectScrollbar(this.refs.wrapper);
    // }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    // if (this.ps) {
    //   this.ps.destroy();
    //   this.ps = null;
    // }
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header" ref="pageHeader">
            <img
              alt="..."
              className="path"
              src={require("assets/img/logo-short.svg")}
            />
            <Container>
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg="6" md="6">
                  <h1 className="text-white">BMP - Background Music Player</h1>
                  <p>
                    Background Music Player is a simple music app for Xbox. It
                    supports FLAC, MP3, MP4 and few more audio formats.
                    <br />
                    <br />
                    Just insert your NTFS or FAT formatted USB-Storage device
                    into your Xbox and enjoy your favorite music in background
                    while playing.
                  </p>
                  <p>
                    <strong>Features</strong>
                  </p>
                  <ul>
                    <li>Support for Xbox Background Music</li>
                    <li>
                      Supports playback from external USB-Devices that are FAT,
                      FAT32, exFAT or NTFS formatted
                    </li>
                    <li>Supports FLAC, MP3, MP4, M4A (incl. ALAC) and more</li>
                    <li>Supports DLNA Streaming</li>
                  </ul>
                </Col>
                <Col lg="4" md="5">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/bmp.png")}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>Support</CardHeader>
                    <CardBody>
                      <p>
                        For support, you can contact us at:{" "}
                        <strong>
                          <a
                            href="mailto:bmp@deveent.com?Subject=[Support]"
                            target="_top"
                          >
                            bmp@deveent.com
                          </a>
                        </strong>
                      </p>
                      <p>
                        For feature requests begin with{" "}
                        <strong>[Feature]</strong> in subject line:{" "}
                        <strong>
                          <a
                            href="mailto:bmp@deveent.com?Subject=[Feature]"
                            target="_top"
                          >
                            bmp@deveent.com
                          </a>
                        </strong>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>Privacy Policy</CardHeader>
                    <CardBody>
                      <p>
                        This application does not share personal information
                        with third parties nor does it store any information
                        about you.
                      </p>
                      <p>
                        We use HockeyApp (
                        <a href="https://www.hockeyapp.net">www.hockeyapp.net</a>) to
                        collect and send anonymous error reports if the App
                        behaves in an unexpected way, especially if the App
                        crashes.
                      </p>
                      <p>
                        This privacy policy is subject to change without notice
                        and was last updated on January 10th 2017.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default BMP;
