import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.0.0";
import "assets/css/site.css";
// import "assets/demo/demo.css";
// import "assets/demo/react-demo.css";

// presentation pages

// example pages

import LandingPage from "views/LandingPage.jsx";
import Imprint from "views/Imprint.jsx";
import BMP from "views/BMP.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/bmp" render={props => <BMP {...props} />} />
      <Route path="/imprint" render={props => <Imprint {...props} />} />
      <Route path="/" render={props => <LandingPage {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
