import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// import PerfectScrollbar from "perfect-scrollbar";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import Footer from "components/Footers/Footer.jsx";

class LandingPage extends React.Component {
  componentDidMount() {
    // if (!this.ps) {
    //   this.ps = new PerfectScrollbar(this.refs.wrapper);
    // }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");

  }
  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    // if (this.ps) {
    //   this.ps.destroy();
    //   this.ps = null;
    // }
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header" ref="pageHeader">
            <img
              alt="..."
              className="path"
              src={require("assets/img/logo-short.svg")}
            />
            <Container>
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg="6" md="6">
                  {/* <h1 className="text-white">We make software</h1> */}
                  {/* <p className="text-white mb-3">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum.
                  </p> */}
                </Col>
                <Col lg="4" md="5">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/logo-short.svg")}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default LandingPage;
