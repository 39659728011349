import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

// import PerfectScrollbar from "perfect-scrollbar";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.jsx";
import Footer from "components/Footers/Footer.jsx";

class Imprint extends React.Component {
  componentDidMount() {
    // if (!this.ps) {
    //   this.ps = new PerfectScrollbar(this.refs.pageHeader);
    // }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("landing-page");
    // if (this.ps) {
    //   this.ps.destroy();
    //   this.ps = null;
    // }
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header" ref="pageHeader">
            <img
              alt="..."
              className="path"
              src={require("assets/img/logo-short.svg")}
            />
            <Container className="imprint">
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }}>
                  <h1>Impressum</h1>
                  <div className="ce_text block">
                    <h3>Angaben gemäß § 5 TMG:</h3>
                    <p>
                      <strong>Betreiber und Kontakt</strong>
                    </p>
                    <p>
                      Valentin Slobozanin
                      <br />
                      deveent
                    </p>
                    <p>
                      Berliner Straße 9<br />
                      69412 Eberbach
                    </p>
                    <p>E-Mail: info@deveent.com</p>
                    <p>
                      <strong>Umsatzsteuer-ID: DE308945836</strong>
                      <br />
                      <br />
                    </p>
                    <p>
                      <strong>Haftung für Inhalte </strong>
                      <br />
                      Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene
                      Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                      verantwortlich. Nach §§ 8 bis 10 TMG bin ich als
                      Diensteanbieter jedoch nicht verpflichtet, übermittelte
                      oder gespeicherte fremde Informationen zu überwachen oder
                      nach Umständen zu forschen, die auf eine rechtswidrige
                      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                      Sperrung der Nutzung von Informationen nach den
                      allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                      der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                      Bekanntwerden von entsprechenden Rechtsverletzungen werde
                      ich diese Inhalte umgehend entfernen.
                    </p>
                    <br />
                    <p>
                      <strong>Haftung für Links</strong>
                      <br />
                      Mein Angebot enthält Links zu externen Webseiten Dritter,
                      auf deren Inhalte ich keinen Einfluss habe. Deshalb kann
                      ich für diese fremden Inhalte auch keine Gewähr
                      übernehmen. Für die Inhalte der verlinkten Seiten ist
                      stets der jeweilige Anbieter oder Betreiber der Seiten
                      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
                      der Verlinkung auf mögliche Rechtsverstöße überprüft.
                      Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                      nicht erkennbar. Eine permanente inhaltliche Kontrolle der
                      verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
                      von Rechtsverletzungen werde ich derartige Links umgehend
                      entfernen.
                    </p>
                  </div>
                  <br />
                  <div className="ce_text block">
                    <p>
                      <strong>Urheberrecht</strong>
                      <br />
                      Die durch die Seitenbetreiber erstellten Inhalte und Werke
                      auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                      Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                      Art der Verwertung außerhalb der Grenzen des
                      Urheberrechtes bedürfen der schriftlichen Zustimmung des
                      jeweiligen Autors bzw. Erstellers. Downloads und Kopien
                      dieser Seite sind nur für den privaten, nicht
                      kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                      dieser Seite nicht vom Betreiber erstellt wurden, werden
                      die Urheberrechte Dritter beachtet. Insbesondere werden
                      Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                      trotzdem auf eine Urheberrechtsverletzung aufmerksam
                      werden, bitte ich um einen entsprechenden Hinweis. Bei
                      Bekanntwerden von Rechtsverletzungen werde ich derartige
                      Inhalte umgehend entfernen.
                    </p>
                    <p>Quelle: http://www.e-recht24.de</p>
                    <p>
                      <strong>
                        Gemäß § 28 BDSG widersprechen wir jeder kommerziellen
                        Verwendung und Weitergabe unserer Daten.
                      </strong>
                    </p>
                    <br />
                    <br />
                  </div>
                  <div className="ce_text info-box grid10 last block">
                    <h3>Hinweis:</h3>
                    <p>
                      Zur besseren Lesbarkeit verwenden wir in diesen Texten nur
                      die männliche Form. <br />
                      Die weibliche Form ist selbstverständlich immer
                      miteingeschlossen.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Imprint;
